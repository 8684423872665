import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../../../LoadingPage';

const FindifyRecommendations = loadable(
  () => import('./FindifyRecommendations'),
  {
    fallback: <LoadingPage />
  }
);

export default FindifyRecommendations;
