import React from 'react';
import { styled } from 'linaria/react';
import DynamicComponent from '../Storyblok/ui/DynamicComponent';
import { FindifyTracker } from '../Findify';

const StartPageWrapper = styled('div')``;

const StartPage = story => {
  if (!story) return null;
  return (
    <StartPageWrapper>
      <FindifyTracker />
      {story?.hero_image && <DynamicComponent story={story.hero_image} />}
      {story?.content && <DynamicComponent story={story.content} />}
    </StartPageWrapper>
  );
};

export default StartPage;
